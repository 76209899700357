<template>
    <div>
        <div class="setting_block">
            <a-spin class="w-full" size="small" :spinning="loading1">
                <div class="setting_block__head">
                    <div class="setting_block__head--label">Могут просматривать мои планы:</div>
                </div>
                <div>
                    <a-form-model
                        ref="ruleForm1"
                        :model="form1">
                        <a-form-model-item ref="organizations" label="Организации" prop="organizations" class="mb-2">
                            <OrganizationsDrawer
                                v-model="form1.organizations"
                                title="Укажите организацию"
                                inputSize="large" />
                        </a-form-model-item>
                        <a-form-model-item ref="users" label="Пользователи из списка" prop="users" class="w-full mb-0">
                            <UserDrawer
                                key="form1"
                                v-model="form1.users"
                                :metadata="{ key: 'users', value: form1.metadata }"
                                :changeMetadata="changeMetadata(form1)"
                                id="empty_task"
                                :oldSelected="false"
                                moreTag
                                multiple
                                inputSize="large"
                                title="Укажите пользователей" />
                        </a-form-model-item>
                    </a-form-model>
                    <a-button 
                        type="primary" 
                        :loading="form1Loading"
                        :disabled="form1Disabled"
                        class="mt-3 px-6"
                        @click="form1Submit()">
                        Сохранить
                    </a-button>
                </div>
            </a-spin>
        </div>
        <div class="setting_block">
            <a-spin class="w-full" size="small" :spinning="loading2">
                <div class="setting_block__head">
                    <div class="setting_block__head--label">Могут просматривать календарь событий:</div>
                </div>
                <div>
                    <a-form-model
                        ref="ruleForm2"
                        :model="form1">
                        <a-form-model-item ref="organizations" label="Организации" prop="organizations" class="mb-2">
                            <OrganizationsDrawer
                                v-model="form2.organizations"
                                title="Укажите организацию"
                                inputSize="large" />
                        </a-form-model-item>
                        <a-form-model-item ref="users" label="Пользователи из списка" prop="users" class="w-full mb-0">
                            <UserDrawer
                                key="form2"
                                v-model="form2.users"
                                :metadata="{ key: 'users', value: form2.metadata }"
                                :changeMetadata="changeMetadata(form2)"
                                id="empty_task"
                                :oldSelected="false"
                                moreTag
                                multiple
                                inputSize="large"
                                title="Укажите пользователей" />
                        </a-form-model-item>
                    </a-form-model>
                    <a-button 
                        type="primary" 
                        :loading="form2Loading"
                        :disabled="form2Disabled"
                        class="mt-3 px-6"
                        @click="form2Submit()">
                        Сохранить
                    </a-button>
                </div>
            </a-spin>
        </div>
    </div>
</template>

<script>
import OrganizationsDrawer from '../components/OrganizationsDrawer.vue'
import UserDrawer from "@apps/DrawerSelect/index.vue"
import Vue from 'vue'
export default {
    components: {
        OrganizationsDrawer,
        UserDrawer
    },
    computed: {
        form1Disabled() {
            return this.form1.organizations.length || this.form1.users.length ? false : true
        },
        form2Disabled() {
            return this.form2.organizations.length || this.form2.users.length ? false : true
        }
    },
    data() {
        return {
            form1Loading: false,
            form2Loading: false,
            loading1: false,
            loading2: false,
            form1: {
                organizations: [],
                users: [],
                metadata: {}
            },
            form2: {
                organizations: [],
                users: [],
                metadata: {}
            }
        }
    },
    created() {
        this.getPlans()
        this.getCalendar()
    },
    methods: {
        changeMetadata(form) {
            return ({ key, value }) => {
                Vue.set(form.metadata, key, value);
            }
        },

        async form1Submit() {
            try {
                this.form1Loading = true
                const queryData = {...this.form1}
                if(queryData.organizations.length)
                    queryData.organizations = queryData.organizations.map(item => item.id)
                if(queryData.users.length)
                    queryData.users = queryData.users.map(item => item.id)
                const { data } = await this.$http.put('/personal_planes/access/update/', queryData)
                if(data) {
                    this.$message.success('Изменения сохранены')
                }
            } catch(e) {
                this.$message.error('Ошибка')
                console.log(e)
            } finally {
                this.form1Loading = false
            }
        },
        async form2Submit() {
            try {
                this.form2Loading = true
                const queryData = {...this.form2}
                if(queryData.organizations.length)
                    queryData.organizations = queryData.organizations.map(item => item.id)
                if(queryData.users.length)
                    queryData.users = queryData.users.map(item => item.id)
                const { data } = await this.$http.put('/calendars/access/update/', queryData)
                if(data) {
                    this.$message.success('Изменения сохранены')
                }
            } catch(e) {
                this.$message.error('Ошибка')
                console.log(e)
            } finally {
                this.form2Loading = false
            }
        },
        async getPlans() {
            try {
                this.loading1 = true
                const { data } = await this.$http.get('/personal_planes/access/')
                if(data) {
                    this.form1 = data
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading1 = false
            }
        },
        async getCalendar() {
            try {
                this.loading2 = true
                const { data } = await this.$http.get('/calendars/access/')
                if(data) {
                    this.form2 = data
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading2 = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.setting_block{
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 20px;
    color: #000;
    margin-bottom: 20px;
    &__head{
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        &--label{
            font-size: 16px;
        }
    }
}
</style>